import PropTypes from "prop-types";
import { memo } from "react";

import Footer from "components/Layout/FooterAsync";

import { useStyles } from "hooks/useStyles";

import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  top__wrapper: {
    [ScreenSizes.lgAndAbove]: {
      display: "grid",
      gridTemplateRows: "max-content 1fr",
      overflow: "auto",
      height: "100%",
    },
  },
  topWrapperBody: {
    [ScreenSizes.lgAndAbove]: {
      height: "100%",
      overflow: "auto",
    },
  },
  topWrapperHeader: {
    [ScreenSizes.lgAndAbove]: {
      position: "fixed",
      top: 0,
      zIndex: 100,
      width: "100%",
    },
  },
  topHeaderPlaceholder: {
    minHeight: "4.5rem",

    [ScreenSizes.lgAndAbove]: {
      minHeight: "7.5rem",
      position: "relative",
      width: "100vw",
    },

    [createBreakpoint({ min: 1400 })]: {
      minHeight: "4.5rem",
    },
  },

};

function TopLevelRouteStructure(props) {
  const {  withFooter, header, children } = props;

  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.top__wrapper)}>
      <div
        className={css(
          styles.topHeaderPlaceholder,
          
        )}
      >
        <div className={css(styles.topWrapperHeader)}>{header}</div>
      </div>
      <div className={css(styles.topWrapperBody)}>
        {children}
        {withFooter && <Footer />}
      </div>
    </div>
  );
}

TopLevelRouteStructure.propTypes = {
  withFooter: PropTypes.bool,
  header: PropTypes.node,
  children: PropTypes.node,
};

TopLevelRouteStructure.defaultProps = {
  withFooter: false,
};

export default memo(TopLevelRouteStructure);
